.author {
  font-style: italic
}

.quote {
  margin-left: 32px;
  background-color: #f3f3f3

}

hr {
  border-color:#f3f3f3
}

img {
  width: 32px
}
